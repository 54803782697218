/*
 * @Author: your name
 * @Date: 2022-02-23 18:27:08
 * @LastEditTime: 2022-04-08 15:18:40
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \ShopTask-goshop\src\i18n\index.js
 */
import Vue from "vue";
import VueI18n from "vue-i18n";
import Cookies from "js-cookie";
import enLocale from "./en";
import inLocale from "./idn";
import thLocale from "./th";
import trLocale from "./tr";
import esLocale from "./es";
import verConfig from '@/utils/ver.config'
import zhLocale from "./zh";

Vue.use(VueI18n);

const messages = {
  // en: {
  //   ...enLocale,
  // },
  // zh: {
  //   ...zhLocale,
  // },
  idn: {
    ...inLocale,
  },
  // th: {
  //   ...thLocale,
  // },
  // tr: {
  //   ...trLocale,
  // },
  // es: {
  //   ...esLocale
  // }
};

export function getLanguage() {
  const chooseLanguage = Cookies.get("language");
  if (!chooseLanguage) {
    Cookies.set("language", verConfig.lan || 'idn');
    localStorage.setItem('language',  verConfig.lan || 'idn')
  }
  if (chooseLanguage) return chooseLanguage;
  const language = (
    navigator.language || navigator.browserLanguage
  ).toLowerCase();
  const locales = Object.keys(messages);
  for (const locale of locales) {
    if (language.indexOf(locale) > -1) {
      return locale;
    }
  }
  return verConfig.lan || 'idn';
}
const i18n = new VueI18n({
  locale: getLanguage(),
  messages,
});

export default i18n;
