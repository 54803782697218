<template>
  <div id="app">
    <router-view :style="{'--navBg':'rgb(35, 47, 62)'}" />
    <!-- <van-tabbar v-model="active" :change="onChange" style="width:100%;" :placeholder="true">
      <van-tabbar-item icon="home-o" @click="goto('goods')">{{ $t("tab.home") }}</van-tabbar-item>
      <van-tabbar-item icon="search" @click="goto('financing')">{{ $t("tab.financing") }}</van-tabbar-item>
      <van-tabbar-item icon="friends-o" @click="goto('cart')">{{ $t("tab.grab") }}</van-tabbar-item>
      <van-tabbar-item icon="setting-o" @click="goto('record')">{{ $t("tab.record") }}</van-tabbar-item>
      <van-tabbar-item icon="setting-o" @click="goto('user')">{{
        $t("tab.my")
      }}</van-tabbar-item>
    </van-tabbar> -->
  </div>
</template>
<script>
import { Tabbar, TabbarItem } from 'vant'
export default {
  data() {
    return {
      active: 0,
      timer: null
    }
  },
  components: {
    [Tabbar.name]: Tabbar,
    [TabbarItem.name]: TabbarItem,
  },
  created() {
    // if (!localStorage.getItem('user-info')) {
    //   if (this.$router.history.current.name !== 'login') {
    //     // this.$router.replace('login')
    //     this.$router.push('login')
    //   }
    // }
    sessionStorage.setItem('version-dialog-show', true)
    if (!localStorage.getItem('version')) {
      localStorage.setItem('version', '1.0.7')
    }
  },
  computed: {
    ifShowTabbar() {
      let list = ['goods', 'financing', 'cart', 'record', 'user']
      // console.log(list.includes(this.$router.history.current.name))
      return list.includes(this.$router.history.current.name)
    },
  },
  mounted() {
    // this.timer = setInterval(() => {
    //   if(this.$route.path === "/login" || this.$route.path === "/registrasi") return;
    //   this.$store.dispatch("getUserInfo");
    // }, 10 * 1000)
  },
  methods: {
    onChange() {
      // window.console.log(123)
    },
    goto(path = ``) {
      this.$router.push(path)
    },
  },
  beforeDestroy() {
    clearInterval(this.timer);
    this.timer = null;
  },
  destroyed() {
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = null;
    }
  }
}

window.onload = function () {
  document.addEventListener('touchstart', function (event) {
    if (event.touches.length > 1) {
      event.preventDefault()
    }
  })
  document.addEventListener('gesturestart', function (event) {
    event.preventDefault()
  })
}
</script>
<style>
#app {
  width: 100%;
  height: 100%;
}

body {
  font-size: 16px;
  background-color: #fff;
  -webkit-font-smoothing: antialiased;
}

a {
  position: fixed;
  top: -1000px;
}
</style>
