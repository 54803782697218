import { $get, $post, $upload, dealError } from "../utils/newRequest";
import { setToken } from "../utils/tools"

export const GET_USERINFO = async () => {
  try {
    const [{ data: taskData }, { data: userData }] = await Promise.all([$get("/task/user"), $get("users/info")])
    delete taskData.id;
    return Promise.resolve({ ...userData, ...taskData });
  } catch (error) {
    dealError(error);
    return Promise.reject(error)
  }
}

export const GET_TASKINFO = async () => {
  try {
    const { data } = await $get("/task/user");
    return Promise.resolve(data)
  } catch (error) {
    dealError(error)
    return Promise.reject(error)
  }
};

export const LOGIN = async (params) => {
  try {
    const { data } = await $post("/users/login", params);
    setToken(data.token);
    const taskData = await GET_TASKINFO();
    delete taskData.id;
    return Promise.resolve({ ...data, ...taskData });
  } catch (error) {
    dealError(error);
    return Promise.reject(error)
  }
}

export const GET_TASK = (data) => $post('/task/getTask', data, { showToast: false })