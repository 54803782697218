import Vue from 'vue'
import Vuex from 'vuex'
import user from './user'
import system from "./systemConfig"
import { Api } from '@/api'

Vue.use(Vuex)

export default new Vuex.Store({
  strict: false,
  state: {
    PopUp: false,
    index: 0,
    levels: []
  },
  mutations: {
    updataCount (state,data){
      state.PopUp = data
    },
    upindex(state, data) {
      state.index = data
    },
    getLevel(state){
      Api.getLevel(res=>{
        state.levels = res;
      })
    }
  },
  modules: {
    user,
    system
  }
})
