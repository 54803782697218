// import { $post, $get, dealError } from "@/utils"
import { router } from "../router"
// import { setToken } from "../utils/tools"
import { GET_USERINFO, GET_TASKINFO, LOGIN } from "../api/newApi";
import store from "./index"
import { Toast } from "vant";
let loading = false;
const user = {
  state: {
    userInfo: {},
  },
  mutations: {
    SET_USERINFO(state, data) {
      if(data.isTask){
        return state.userInfo = { ...state.userInfo, ...data.userInfo };
      }
      state.userInfo = {...data}
    }
  },
  getters: {
    userInfo(state) {
      const token = state.userInfo.token;
      if (!token) {
        store.dispatch("getUserInfo")
      }
      return state.userInfo;
    }
  },
  actions: {
    login({ commit }, params) {
      return new Promise((resolve, reject) => {
        LOGIN(params).then(res => {
          commit("SET_USERINFO", res);
          router.replace({ name: "goods" })
          resolve(res);
        }).catch((err)=> {
          reject(err)
        })
      })
    },
    getUserInfo({ commit }) {
      Toast.loading({
        forbidClick: true,
        duration: 0,
      });
      return new Promise((resolve, reject) => {
        if (loading) return;
        loading = true;
        GET_USERINFO().then((res) => {
          loading = false;
          commit("SET_USERINFO", res)
          Toast.clear()
        }).catch(err => {
          Toast.clear()
          loading = false;
        })
      })
    },
    updateTaskUserInfo({ commit }) {
      Toast.loading({
        forbidClick: true,
        duration: 0,
      });
      return new Promise((resolve, reject) => {
        GET_TASKINFO().then((res) => {
          delete res.id;
          commit("SET_USERINFO", {isTask: true, userInfo: res})
          Toast.clear()
        }).catch(err => {
          Toast.clear()
        })
      })
    }
  },
}
export default user
