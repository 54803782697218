import verConfig from "@/utils/ver.config"
export default function (url, isLocal = false) {
  if(isLocal){
    return url;
  }
  const reg = /^http*/
  if (reg.test(url)) {
    return url
  }
  if (url && url.charAt(0) === '/') {
    url = url.substring(1, url.length);
  }
  return verConfig.url + url;
}