import Vue from 'vue'
import App from './App'
import store from './store'
import { router } from './router'
import i18n from './i18n/index'
import VueI18n from 'vue-i18n'
import Module from './utils/Module'
import './styles/index.css'
import VueClipboard from 'vue-clipboard2'
// import '@/assets/iconfont/iconfont.css'
import '@/assets/iconfonts/iconfont.css'
import { Api } from '@/api'
import ImgFormat from './utils/ImgFormat'
import formatMoney from './utils/formatMoney'
import { formatNumber } from './utils/common'
Vue.use(VueClipboard)

import { cookie } from '@/utils/tools'

import { Loading } from 'vant'
Vue.use(Loading)
Vue.prototype.module = Module
Vue.prototype.$cookie = cookie
Vue.prototype.$api = Api;
Vue.prototype.$store = store;
Vue.prototype.$img = ImgFormat;
Vue.prototype.$money = formatMoney;
Vue.prototype.$formatNumber = formatNumber;

import verConfig, { ver } from '@/utils/ver.config'

Vue.prototype.ver = ver;
Vue.prototype.verConfig = verConfig;
// 动态更改网站标题
document.getElementsByTagName('title')[0].innerText = verConfig.title;
// console.log(verConfig.title);
// 动态更改网站图标
const icoLink = document.createElement('link');
icoLink.type = 'image/png';
icoLink.rel = 'icon';
const icoVer = ver;
icoLink.href = require(`@/assets/ico/${icoVer}.png`);
document.head.appendChild(icoLink);

Vue.use(VueI18n, {
  i18n: (key, value) => i18n.t(key, value),
})

Vue.filter("lan", (val)=> i18n.t(val));
Vue.filter("money", (val)=> formatMoney(val))

new Vue({
  router,
  store,
  i18n,
  el: '#app',
  render: (h) => h(App),
})
