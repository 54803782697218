export const formatNumber = (num) => {
  if (isNaN(Number(num))) {
    return num;
  }
  const parts = num.toString().split(".");
  let integerPart = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  let decimalPart = parts[1] || "";
  decimalPart = decimalPart.padEnd(2, "0");
  decimalPart = decimalPart.slice(0, 2);
  return decimalPart ? `${integerPart},${decimalPart}` : integerPart;
};