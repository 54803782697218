export const ver = 'T7103'

// 可选语言lan,默认en
const verData = {
  T7102: {
    title: 'ZALORA',
    url: 'https://zalorashop.store/',
    // areaCode: '+91',
    currency: 'IDR',
    theme: "#29bc23",
    useNewRegister: true,
    bankInput: true,
    lan: "idn",
    lans: [
      //   {
      //   type: 'zh',
      //   name: '中文',
      //   icon: require('@/assets/Img/lan/zh.jpg'),
      // },
      {
        type: 'idn',
        name: "Indonesia",
        icon: require('@/assets/Img/lan/idn.jpg'),
      }]
  },
  T7102_test: {
    title: 'ZALORA',
    url: 'https://gt7102.gtzs.cc/',
    // areaCode: '+91',
    currency: 'IDR',
    theme: "#29bc23",
    useNewRegister: true,
    bankInput: true,
    lan: "idn",
    lans: [
      //   {
      //   type: 'zh',
      //   name: '中文',
      //   icon: require('@/assets/Img/lan/zh.jpg'),
      // },
      {
        type: 'idn',
        name: "Indonesia",
        icon: require('@/assets/Img/lan/idn.jpg'),
      }]
  },
  T7103: {
    title: 'ZALORA',
    url: 'https://api.zalorashop.shop/',
    // areaCode: '+91',
    currency: 'IDR',
    theme: "#29bc23",
    useNewRegister: true,
    bankInput: true,
    lan: "idn",
    lans: [
      //   {
      //   type: 'zh',
      //   name: '中文',
      //   icon: require('@/assets/Img/lan/zh.jpg'),
      // },
      {
        type: 'idn',
        name: "Indonesia",
        icon: require('@/assets/Img/lan/idn.jpg'),
      }]
  }
}

export default verData[ver];