import Vue from 'vue'
import Router from 'vue-router'
import verConfig from './utils/ver.config'
Vue.use(Router)

const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err)
}

const routes = [
  {
    path: '*',
    redirect: '/goods',
  },
  {
    path: '/cart',
    name: 'cart',
    component: () => import('@/view/cart/index'),
    meta: {
      title: 'cart',
    },
  }, // 商城
  {
    path: '/order',
    name: 'order',
    component: () => import('@/view/cart/order'),
    meta: {
      title: '订单',
    },
  },
  {
    path: '/Financing',
    name: 'Financing',
    component: () => import('@/view/Financing/index'),
    meta: {
      title: 'Financing',
    },
  },
  {
    path: '/goods',
    name: 'goods',
    component: () => import('@/view/goods/index'),
    meta: {
      title: 'goods',
    },
  },
  {
    path: '/games',
    name: 'games',
    component: () => import('@/view/goods/game/index'),
    meta: {
      title: 'games'
    }
  },
  {
    path: '/gamesOrder',
    name: 'gamesOrder',
    component: () => import('@/view/goods/game/gameOrder'),
    meta: {
      title: 'gamesOrder'
    }
  },
  // 首页下的子页面 - 等级详情
  {
    path: '/levelUpgrade',
    name: 'levelUpgrade',
    component: () => import('@/view/goods/levelUpgrade/levelUpgrade')
  },
  // 首页下的子页面 - 小饶
  {
    path: '/PlatformIntroduction',
    name: 'PlatformIntroduction',
    component: () => import('@/view/goods/Subpage/PlatformIntroduction'),
    meta: {
      title: 'PlatformIntroduction',
    },
  },
  {
    path: '/RuleDescription',
    name: 'RuleDescription',
    component: () => import('@/view/goods/Subpage/RuleDescription'),
    meta: {
      title: 'RuleDescription',
    },
  },
  {
    path: '/AgentCooperation',
    name: 'AgentCooperation',
    component: () => import('@/view/goods/Subpage/AgentCooperation'),
    meta: {
      title: 'AgentCooperation',
    },
  },
  {
    path: '/CommonProblem',
    name: 'CommonProblem',
    component: () => import('@/view/goods/Subpage/CommonProblem'),
    meta: {
      title: 'CommonProblem',
    },
  },
  // 
  {
    path: '/Invite',
    name: 'Invite',
    component: () => import('@/view/goods/invite/index'),
    meta: {
      title: 'Invite',
    },
  },
  {
    path: '/Cooperate',
    name: 'Cooperate',
    component: () => import('@/view/goods/CompanyProfile/cooperate'),
    meta: {
      title: 'Cooperate',
    },
  },
  {
    path: '/Introduction',
    name: 'Introduction',
    component: () => import('@/view/goods/CompanyProfile/Introduction'),
    meta: {
      title: 'Introduction',
    },
  },
  {
    path: '/Qualification',
    name: 'Qualification',
    component: () => import('@/view/goods/CompanyProfile/Qualification'),
    meta: {
      title: 'Qualification',
    },
  },
  {
    path: '/Rule',
    name: 'Rule',
    component: () => import('@/view/goods/CompanyProfile/rule'),
    meta: {
      title: 'Rule',
    },
  },
  // 首页下的子页面 - 小饶结束
  // 会员升级的页面 - 小饶
  {
    path: '/MemberUpgrade',
    name: 'MemberUpgrade',
    component: () => import('@/view/goods/Subpage/MemberUpgrade'),
    meta: {
      title: 'MemberUpgrade',
    },
  },
  // 会员升级的页面 - 小饶结束
  {
    path: '/login',
    name: 'login',
    component: () => import('@/view/login/index'),
    meta: {
      title: 'login',
    },
  },
  {
    path: '/forgot',
    name: 'forgot',
    component: () => import('@/view/login/forgot'),
    meta: {
      title: 'forgot',
    },
  },
  {
    path: '/registrasi',
    name: 'registrasi',
    component: () => verConfig.useNewRegister ? import("@/view/login/newRegister") : import('@/view/login/registrasi'),
    meta: {
      title: 'registrasi',
    },
  },
  {
    path: '/record',
    name: 'record',
    component: () => import('@/view/record/index'),
    meta: {
      title: 'record',
    },
  },
  // ========= 首页-子页面跳转（充值） ==========
  {
    path: '/Recharge',
    name: 'Recharge',
    component: () => import('@/view/goods/Recharge/Recharge'),
    meta: {
      title: 'Recharge',
    },
  },
  // ========= 首页-子页面跳转（收回）========
  {
    path: '/Withdraw',
    name: 'Withdraw',
    component: () => import('@/view/goods/Withdraw/Withdraw'),
    meta: {
      title: 'Withdraw',
    },
  },
  // ========= 首页-子页面(客服) =========
  {
    path: '/Service',
    name: 'Service',
    component: () => import('@/view/goods/Service/Service'),
    meta: {
      title: 'Service',
    },
  },
  {
    path: '/DialoguePage',
    name: 'DialoguePage',
    component: () => import('@/view/goods/Service/DialoguePage'),
    meta: {
      title: 'Service',
    },
  },
  //========== 个人中心-小饶  ==========
  {
    path: '/user',
    name: 'user',
    component: () => import('@/view/user/index'),
    meta: {
      title: 'Personal'  // '个人',
    },
  },
  // =========== 理财—转出记录跳转 =======
  {
    path: '/RansferIn',
    name: 'RansferIn',
    component: () => import('@/view/Financing/RansferIn/RansferIn'),
    meta: {
      title: 'RansferIn' //'记录',
    },
  },
  {
    //========== 个人中心-设置-小饶  ==========
    path: '/PersonalCenter',
    name: 'PersonalCenter',
    component: () => import('@/view/PersonalCenter/index'),
    meta: {
      title: 'PersonalCenter' //'个人中心',
    },
  },
  {
    //========== 个人中心-头像-小饶  ==========
    path: '/Portrait',
    name: 'Portrait',
    component: () => import('@/view/PersonalCenter/Portrait'),
    meta: {
      title: 'Portrait',
    },
  },
  {
    //========== 个人中心-名称-小饶  ==========
    path: '/Name',
    name: 'Name',
    component: () => import('@/view/PersonalCenter/Name'),
    meta: {
      title: 'Name',
    },
  },
  {
    //========== 个人中心-账户-小饶  ==========
    path: '/Account',
    name: 'Account',
    component: () => import('@/view/PersonalCenter/Account'),
    meta: {
      title: 'Account',
    },
  },
  {
    //========== 个人中心-密码-小饶  ==========
    path: '/Password',
    name: 'Password',
    component: () => import('@/view/PersonalCenter/Password'),
    meta: {
      title: 'Password',
    },
  },
  {
    //========== 个人中心-支付密码-小饶  ==========
    path: '/PaymentCode',
    name: 'PaymentCode',
    component: () => import('@/view/PersonalCenter/PaymentCode'),
    meta: {
      title: 'PaymentCode',
    },
  },
  {
    //========== 个人中心-修改地址-小饶  ==========
    path: '/Site',
    name: 'Site',
    component: () => import('@/view/PersonalCenter/Site'),
    meta: {
      title: 'Site',
    },
  },
  //========== 个人中心-账户详细资料-小饶 ==========
  {
    path: '/AccountData',
    name: 'AccountData',
    component: () => import('@/view/PersonalCenter/AccountData/AccountData'),
    meta: {
      title: 'AccountData',
    },
  },
  //========== 个人中心-充值记录-小饶 ==========
  {
    path: '/RechargeRecord',
    name: 'RechargeRecord',
    component: () =>
      import('@/view/PersonalCenter/RechargeRecord/RechargeRecord'),
    meta: {
      title: 'RechargeRecord',
    },
  },
  //========== 个人中心-提款记录-小饶 ==========
  {
    path: '/WithdrawalRecord',
    name: 'WithdrawalRecord',
    component: () =>
      import('@/view/PersonalCenter/WithdrawalRecord/WithdrawalRecord'),
    meta: {
      title: 'WithdrawalRecord',
    },
  },
  //========== 个人中心-邀请朋友-小饶 ==========
  {
    path: '/InviteFriends',
    name: 'InviteFriends',
    component: () =>
      import('@/view/PersonalCenter/InviteFriends/InviteFriends'),
    meta: {
      title: 'InviteFriends',
    },
  },
  //========== 个人中心-系统公告-小饶 ==========
  {
    path: '/Announcement',
    name: 'Announcement',
    component: () => import('@/view/PersonalCenter/Announcement/Announcement'),
    meta: {
      title: 'Announcement',
    },
  },
  //========== 个人中心-团队报告-小饶 ==========
  {
    path: '/TheTeamReport',
    name: 'TheTeamReport',
    component: () =>
      import('@/view/PersonalCenter/TheTeamReport/TheTeamReport'),
    meta: {
      title: 'TheTeamReport',
    },
  },
  //========== 个人中心-小饶  结束 ==========
  /**
   * 20201207
   * 玩家查询充值流水记录
   */
  {
    path: '/bill-record',
    name: 'BillRecord',
    component: () => import('@/view/PersonalCenter/RechargeRecord/billRecord'),
    meta: {
      title: 'billRecord',
    },
  },
  /**
   * new pages
   */
  {
    path: '/add-bank-card',
    name: 'BankCardAdd',
    component: () => import('@/view/bank/add-bank-card'),
    meta: {
      title: 'add-bank-card',
    },
  },
  {
    path: '/new-recharge',
    name: 'NewRecharge',
    component: () => import('@/view/bank/recharge'),
    meta: {
      title: 'recharge',
    },
  },
  {
    path: '/recharge-list',
    name: 'WithdrawList',
    component: () => import('@/view/bank/recharge-list'),
    meta: {
      title: 'recharge-list',
    },
  },
  {
    path: '/new-withdraw',
    name: 'newWithdraw',
    component: () => import('@/view/bank/withdraw'),
    meta: {
      title: 'withdraw',
    },
  },
  {
    path: '/withdraw-list',
    name: 'withdrawList',
    component: () => import('@/view/bank/withdraw-list'),
    meta: {
      title: 'withdraw',
    },
  },
  {
    path: '/setting-pay-pwd',
    name: 'SettingPayPwd',
    component: () => import('@/view/bank/setting-pay-pwd'),
    meta: {
      title: 'setting-pay-pwd',
    },
  },
  {
    path: '/make-money',
    name: 'MakeMoney',
    component: () => import('@/view/new/make-money'),
    meta: {
      title: 'make-money',
    },
  },
  {
    path: '/QRCodePay',
    name: 'qrPay',
    component: () => import('@/view/bank/qrcode'),
    meta: {
      title: 'qrcode-pay'
    }
  },
  {
    path: '/BankPay',
    name: 'bankPay',
    component: () => import('@/view/bank/bank_pay'),
    meta: {
      title: 'bank-pay'
    }
  },
]

// add route path
routes.forEach((route) => {
  route.path = route.path || '/' + (route.name || '')
})

const router = new Router({
  routes,
  // mode:'history'
  mode: 'hash',
})

router.beforeEach((to, from, next) => {
  const title = to.meta && to.meta.title
  if (title) {
    document.title = title
  }
  next()
})

export { router }
